import React, { useCallback, useMemo, useState } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import ScrollEmojiWindow from "./components/ScrollListWindow"
import { isSSR } from "../../components/Script/script"

import ListAddInput from "./components/EmojiListCopyPaste"
import Popup from "./components/Popup"

import {
  Title,
  Dropdown,
  Icon,
  Button,
  ArrowDown,
} from "../../StyleComponents/styles"

const DropDownList = React.lazy(() => import("./components/DropDown"))

const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/text-emoticons-face-generator",
  "/html-entity",
  "/text-art",
]

function EmojiNames(props) {
  const { node, allPages } = props.pageContext

  let [addListEmoji, setaddListEmoji] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("Emoji") || ""
      : ""
  })
  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    e.target.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setEmoji(emoji)
      setShow(true)
    }
  }, [])

  function addTOlistFunction(e) {
    setaddListEmoji(addListEmoji + emoji)
    let btnElement = e.target.firstElementChild
    btnElement.style.display = "block"
    setTimeout(function () {
      btnElement.style.display = "none"
    }, 1500)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title={`${node.name} Emoji Copy And 📋Paste`}
        description={`${node.name} Emoji Copy And 📋Paste Emoji🤯 - Simple beautiful Copy Paste ${node.name} Emoji, Symbols, People and Fantasy, All flag emojis for every country, Travel & Places, Activity and Sports...`}
        keywords={[
          `${node.name} emoji,${node.name} Emoji Copy Paste, ${
            node.name
          } emoji copy,emoji Symbols ${
            node.name
          },emoji People and Fantasy,new ${
            node.name
          } emoji, nice emoji, ${new Date().getFullYear()} emoji, ${
            node.name
          } ${new Date().getFullYear()} emoji`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Utility Tools", link: "/utility-tools/" },
            { name: "All 🤯 EMOJI", link: "/emoji-copy-and-paste/" },
            {
              name: node.name,
              link: `/emoji-copy-and-paste/${node.name
                .replace("&", "and")
                .trim()
                .split(" ")
                .join("-")
                .toLowerCase()}-emoji/`,
            },
          ]
        }, [node.name])}
      />
      <section className="container">
        <Title id="listOfEmoji">{node.name} Emojis</Title>
        <br />
        <Dropdown className="mb-4">
          <Button aria-haspopup="true" aria-controls="dropdown-menu">
            Emojis Category
            <Icon>
              <ArrowDown />
            </Icon>
          </Button>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <DropDownList list={allPages} />
            </React.Suspense>
          )}
        </Dropdown>
        <br />
        {show && (
          <Popup
            emoji={emoji}
            close={() => setShow(false)}
            addToList={addTOlistFunction}
          />
        )}
        <ListAddInput
          valueInput={addListEmoji}
          setValueInput={setaddListEmoji}
        />
        <br />
        <ScrollEmojiWindow pageItems={node.emojis} mycopy={mycopy} />
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmojiNames
